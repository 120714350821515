let data = {
    logo: 'logo.png',
    logo_anim: 'logo.png',
    brand: {
        en: 'Earground. All rights reserved.',
        es: 'Earground. Todos los derechos reservados.',
    },
    slogan: {
        en: '<span class="bolder">Revolutionizing</span> Logistics:<br class="d-none d-md-block"/>Unified <span class="bolder">Simplicity</span> for<br class="d-none d-md-block"/>Maximum <span class="bolder">Efficiency</span>',
        es: '<span class="bolder">Revolucionando</span> la Logística:<br class="d-none d-md-block"/><span class="bolder">Simplicidad</span> unificada para<br class="d-none d-md-block"/>una máxima <span class="bolder">Eficiencia</span>'
    },
    styles: [
        'styles.css'
    ],
    scripts: []
};

export default data;
