import { useEffect } from "react";

export const useDOMTitle = ({ project }) => {
  useEffect(() => {
    if (project) {
      if (project === 'customs') document.title = "Earground Login";
      else document.title = project.toUpperCase() + " Login";
    }
  }, [project]);
};
